// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import { DataGrid } from "@mui/x-data-grid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";
import GENERIC_CONSTANTS from "../../constants/Regulations/Generic";
import getApiHeaders from "../../services/utils.js/apiHeaders";
import RegulationApi from "../../services/RegulationsApi";
import DownloadTemplateButton from "../../components/DownloadTemplateButton";
import UploadPartListButton from "../../components/UploadPartListButton";
import {
  saveRegulationFormData,
  clearRegulationFormData,
  setNewRegulationData,
} from "../../redux/regulationMaintenanceSlice";
import "./addRegulations.css";
import getApiUrls from "../../services/utils.js/apiUrls";
import REGULATIONS_API_BASE from "../../services/axiosApi";

// eslint-disable-next-line react/prop-types
const AddRegulations = ({ isHideDate }) => {
  const getBackendUrl = getApiUrls();
  const dispatch = useDispatch();
  const { regulationFormData, regulationParts, uploadPartsError } = useSelector(
    (state) => state.regulationMaintenanceForm,
  );
  const { userRoles, authxToken, userName } = useSelector((state) => state.loginToken);
  const [newRegulation, setNewRegulation] = useState(regulationFormData);
  const [activeStep, setActiveStep] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [regStateCodes, setRegStateCodes] = useState([]);
  const [regCities, setRegCities] = useState([]);
  const [regCounties, setRegCounties] = useState([]);
  const [showFormInvalid, setShowFormInvalid] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState("");
  const [showApiFailure, setShowApiFailure] = useState(false);
  const [apiFailureMessage, setApiFailureMessage] = useState("");
  const [showApiSuccess, setShowApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [showApiInfo, setShowApiInfo] = useState(false);
  const [apiInfoMessage, setApiInfoMessage] = useState("");
  const [partItemIds, setPartItemIds] = useState([]);
  const [filteredRegulatedParts, setFilteredRegulatedParts] = useState({});
  const [inValidRows, setInValidRows] = useState([]);

  const navigate = useNavigate();
  const getGeolocationDataByParam = async (params, level) => {
    const headers = getApiHeaders(authxToken.access_token);

    const config = {
      params,
      ...headers,
    };
    const apiCallback = (response) => {
      if (response.status === 200) {
        if (
          response
          && response.data
          && response.data
          && response.data.length > 0
        ) {
          setSpinner(false);
          const apiResponse = response.data;
          if (level === "state") {
            setRegStateCodes(apiResponse);
          } else if (level === "county") {
            setRegCounties(apiResponse);
          } else {
            setRegCities(apiResponse);
          }
        }
      } else {
        setShowApiFailure(true);
        setApiFailureMessage(REGULATIONS_CONSTANTS.GEO_LOCATION_API_FAILURE);
      }
    };
    await RegulationApi.getGeolocationDataByParam(config, apiCallback);
  };

  const getAllCounties = (val1, val2) => {
    setSpinner(true);
    const params = {
      "country-code": val1,
      "state-code": val2,
      county: true,
    };
    getGeolocationDataByParam(params, "county");
  };

  const getAllCity = (val1, val2) => {
    setSpinner(true);
    const params = {
      "country-code": val1,
      "state-code": val2,
      city: true,
    };
    getGeolocationDataByParam(params, "city");
  };

  const getAllStateCodes = (val) => {
    setSpinner(true);
    const params = {
      "country-code": val,
    };
    getGeolocationDataByParam(params, "state");
  };

  const handleFormUpdateByKey = (e, key) => {
    const formValue = e.target.value;
    if (key === "jurisdictionLevel") {
      if (
        formValue === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
        || formValue === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[1].value
      ) {
        setNewRegulation({
          ...newRegulation,
          county: "",
          city: "",
          jurisdictionLevel: formValue,
        });
      } else if (formValue === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[2].value) {
        setNewRegulation({
          ...newRegulation,
          city: "",
          jurisdictionLevel: formValue,
        });
      } else {
        setNewRegulation({
          ...newRegulation,
          county: "",
          jurisdictionLevel: formValue,
        });
      }
    } else if (key === "stateCode") {
      setNewRegulation({ ...newRegulation, [key]: formValue });
      getAllCity(newRegulation.countryCode, formValue);
      getAllCounties(newRegulation.countryCode, formValue);
    } else {
      setNewRegulation({ ...newRegulation, [key]: formValue });
    }
  };

  const getFormattedDate = (dateVal) => {
    if (dayjs && typeof dayjs === "function") {
      return dayjs(dateVal).format("YYYY-MM-DD");
    }
    return dateVal;
  };

  const handleDatePickerSelection = (val) => {
    if (val) {
      const formattedEffDate = getFormattedDate(val);
      if (formattedEffDate !== "Invalid Date") {
        setEffectiveDate(val);
        setNewRegulation({ ...newRegulation, effDate: formattedEffDate });
      }
    } else {
      setEffectiveDate("");
      setNewRegulation({ ...newRegulation, effDate: "" });
    }
  };

  const handleAlertClose = () => {
    setShowApiFailure(false);
    setApiFailureMessage("");
  };

  const callSaveRegulation = async () => {
    const headers = getApiHeaders(authxToken.access_token);
    const config = {
      ...headers,
    };
    const apiCallback = (response) => {
      if (response.status === 200) {
        if (response && response.data && response.data.regId) {
          setSpinner(false);
          dispatch(clearRegulationFormData());
          dispatch(setNewRegulationData({ value: response.data }));
        }
      } else {
        setSpinner(false);
        setShowApiFailure(true);
        setApiFailureMessage(REGULATIONS_CONSTANTS.SAVE_API_FAILURE);
      }
    };
    const newRegulationWithUserInfo = { ...newRegulation, createdBy: userName };
    await RegulationApi.saveRegulation(newRegulationWithUserInfo, config, apiCallback);
  };

  const validateForm = (page) => {
    let isValid = false;
    if (page === 0) {
      if (!isHideDate) {
        if (newRegulation.regTitle && newRegulation.effDate) {
          isValid = true;
        }
      } else if (newRegulation.regTitle) {
        isValid = true;
      }
    } else if (page === 1) {
      if (
        newRegulation.jurisdictionLevel
        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
      ) {
        isValid = true;
      } else if (
        newRegulation.jurisdictionLevel
        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[1].value
      ) {
        isValid = true;
      } else if (
        newRegulation.jurisdictionLevel
        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[2].value
      ) {
        if (newRegulation.county) isValid = true;
      } else if (
        newRegulation.jurisdictionLevel
        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[3].value
      ) {
        if (newRegulation.city) isValid = true;
      }
    } else if (page === 2) {
      if (newRegulation.parts && newRegulation.parts.length > 0) {
        isValid = true;
      }
    }
    return isValid;
  };

  const handleNavigation = (action) => {
    if (action === GENERIC_CONSTANTS.BACK) {
      setShowFormInvalid(false);
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
    } else {
      const isFormValid = validateForm(activeStep);
      if (isFormValid) {
        dispatch(saveRegulationFormData({ value: newRegulation }));
        if (activeStep === 2) {
          callSaveRegulation();
          setSpinner(true);
        } else {
          setShowFormInvalid(false);
          const nextStep = activeStep + 1;
          setActiveStep(nextStep);
        }
      } else {
        setShowFormInvalid(true);
      }
    }
  };

  const getUniqParts = (chunk) => {
    const uniq = new Set(chunk.map((e) => JSON.stringify(e)));
    const res = Array.from(uniq).map((e) => JSON.parse(e));
    return res;
  };

  const createQueryData = (chunk) => {
    const res = getUniqParts(chunk);
    let pIds = JSON.stringify(res);
    pIds = pIds.replaceAll("[", "");
    pIds = pIds.replaceAll("]", "");
    pIds = pIds.replaceAll(",", " ");
    const queryData = `query { itemLookUp(input: { itemIds: [${pIds}] filterCondition: { country: US lang: EN isFieldLine: true } } ) { itemErrors { message errorCode itemId } items { title lineAbbreviation partNumber } } }`;
    return queryData;
  };

  const showMessage = (isSuccess, isFailure, validInvalidCount, inValidRowsIndexes) => {
    setApiSuccessMessage("");
    if (isSuccess && !isFailure) {
      setApiSuccessMessage(`${validInvalidCount?.valid} parts loaded. Click submit to process parts.`);
      setShowApiSuccess(true);
    }

    if (!isSuccess && isFailure) {
      setApiFailureMessage(
        `${validInvalidCount?.inValid} parts not loaded. Revisit your parts and try to upload again.`,
      );
      setShowApiFailure(true);
      setInValidRows(inValidRowsIndexes);
    }

    if (isSuccess && isFailure) {
      setApiSuccessMessage("");
      setShowApiSuccess(false);
      setApiInfoMessage("");
      setApiFailureMessage(
        `${validInvalidCount.inValid} out of ${validInvalidCount.valid + validInvalidCount.inValid} parts not loaded correctly. Revisit your parts and try to upload again.`,
      );
      setShowApiFailure(true);
      setInValidRows(inValidRowsIndexes);
    }

    // this is to make the unit testing easy for mocking
    return true;
  };

  /**
   * this function changes the state in the passed data itself
   * @param {boolean} isSuccess
   * @param {*} filterPartsData
   * @param {*} validRowsIndexes
   * @param {*} inValidRowsIndexes
   *
   */
  const createInvalidRows = (isSuccess, filterPartsData, validRowsIndexes, inValidRowsIndexes) => {
    if (!isSuccess) {
      filterPartsData.parts.forEach((fpart, index) => {
        if (validRowsIndexes.indexOf(filterPartsData.parts[index].partNo) === -1
        && inValidRowsIndexes.indexOf(filterPartsData.parts[index].partNo) === -1) {
          inValidRowsIndexes.push(filterPartsData.parts[index].partNo);
          setInValidRows(inValidRowsIndexes);
        }
      });
    }

    return true;
  };

  const validInvalidParts = (filterPartsData, validRowsIndexes, inValidRowsIndexes, pdata) => {
    const catalogValidatedParts = new Map(
      pdata.map((each) => [`${each.lineAbbreviation}-${each.partNumber}`, each]),
    );

    filterPartsData.parts.forEach((fpart) => {
      const validPart = catalogValidatedParts.get(fpart.id);
      // eslint-disable-next-line no-param-reassign
      fpart.partDesc = validPart ? validPart.title : fpart.partDesc;

      if (validPart) {
        validRowsIndexes.push(fpart.partNo);
        // eslint-disable-next-line no-param-reassign
        fpart.inValidRows = false;
      } else {
        inValidRowsIndexes.push(fpart.partNo);
        // eslint-disable-next-line no-param-reassign
        fpart.inValidRows = true;
      }
    });
  };

  useEffect(() => {
    if (partItemIds?.length > 0) {
      setShowApiFailure(false);
      setShowApiInfo(false);
      setShowApiSuccess(false);
      setApiFailureMessage("");
      setApiInfoMessage("");
      setApiSuccessMessage("");
      setInValidRows([]);
      const chunkSize = 50;
      const validInvalidCount = { valid: 0, inValid: 0 };
      const pdata = [];
      const inValidRowsIndexes = [];
      const validRowsIndexes = [];

      const allRegulationsApiBasePromises = [];

      for (let i = 0, len = partItemIds?.length; i < len; i += chunkSize) {
        const chunk = partItemIds.slice(i, i + chunkSize);
        const queryData = createQueryData(chunk);

        setSpinner(true);
        allRegulationsApiBasePromises
          .push(REGULATIONS_API_BASE.post(`${getBackendUrl}/proxycatalog/getCatalogData`, { query: queryData }));
      }

      Promise
        .all(allRegulationsApiBasePromises)
        .then((allRegulationsApiBasePromisesResponses) => {
          allRegulationsApiBasePromisesResponses.forEach((partsData) => {
            if (partsData.data.data.itemLookUp) {
              let isSuccess = false;
              let isFailure = false;

              const filterPartsData = filteredRegulatedParts;

              if (partsData?.data?.data?.itemLookUp?.itemErrors?.length > 0) {
                // if part contains the errors
                isFailure = true;
                // eslint-disable-next-line no-unsafe-optional-chaining
                validInvalidCount.inValid += partsData?.data?.data?.itemLookUp?.itemErrors?.length;
              }
              setSpinner(false);
              if (partsData.data.data.itemLookUp.items
                && partsData.data.data.itemLookUp.items.length > 0) {
                isSuccess = true;
                // eslint-disable-next-line no-unsafe-optional-chaining
                validInvalidCount.valid += partsData?.data?.data?.itemLookUp?.items?.length;
                partsData.data.data.itemLookUp.items.forEach((pt) => {
                  pdata.push(pt);
                });
              }
              validInvalidParts(filterPartsData, validRowsIndexes, inValidRowsIndexes, pdata);

              createInvalidRows(isSuccess, filterPartsData, validRowsIndexes, inValidRowsIndexes);

              showMessage(isSuccess, isFailure, validInvalidCount, inValidRowsIndexes);

              setFilteredRegulatedParts({ ...newRegulation, parts: filterPartsData.parts });
            }
          });
        });
    }
  }, [partItemIds]);

  useEffect(() => {
    if (uploadPartsError && Object.keys(uploadPartsError)[0] === "regulationParts" && uploadPartsError.regulationParts) {
      const errorMsg = uploadPartsError.regulationParts
        ? uploadPartsError.regulationParts : REGULATIONS_CONSTANTS.UNABLE_READ_FILE;
      setApiFailureMessage(errorMsg);
      setShowApiFailure(true);
    }
  }, [uploadPartsError]);

  const validatePartsByCatalog = () => {
    const regulatedPartsPID = regulationParts.map((each) => ((each.lineAbbrev).length === 2 ? `${each.lineAbbrev}_${each.partNo}` : each.lineAbbrev + each.partNo));
    setPartItemIds(regulatedPartsPID);
  };

  useEffect(() => {
    if (regulationParts && regulationParts.length > 0) {
      const regulatedParts = regulationParts.map((each) => ({
        ...each,
        obsoleteDate: each.obsoleteDate ? getFormattedDate(each.obsoleteDate) : each.obsoleteDate,
        id: `${each.lineAbbrev}-${each.partNo}`,
      }));
      const mergedParts = [...regulatedParts];
      const uniqueParts = mergedParts.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.id === current.id)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      setNewRegulation({ ...newRegulation, parts: uniqueParts });
      setShowFormInvalid(false);
      setFilteredRegulatedParts({
        ...newRegulation,
        parts: uniqueParts,
      });
      handleAlertClose();
      validatePartsByCatalog();
    } else if (regulationParts && regulationParts.length === 0) {
      setNewRegulation({ ...newRegulation, parts: regulationParts });
    }
  }, [regulationParts]);

  useEffect(() => {
    if (newRegulation) {
      getAllStateCodes(newRegulation.countryCode);
      getAllCity(newRegulation.countryCode, newRegulation.stateCode);
      getAllCounties(newRegulation.countryCode, newRegulation.stateCode);
    }
    if (userRoles && !userRoles.includes("Block_Sale_RW")) {
      navigate("/landing");
    }
  }, []);

  const partsTableColumns = useMemo(() => [
    {
      field: REGULATIONS_CONSTANTS.PART_ABBR,
      headerName: REGULATIONS_CONSTANTS.FIELD_LINE,
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_NO,
      headerName: REGULATIONS_CONSTANTS.PART_NUMBER,
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_DESC,
      headerName: REGULATIONS_CONSTANTS.PART_DESCRIPTION,
      width: 300,
    },
  ], [newRegulation.parts]);

  const getRowClassName = (params) => (params.row.inValidRows === true ? "inValidRow" : "");

  return (
    <div className="add-regulations-container">
      {showApiFailure && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{ padding: "25px", margin: "20px" }}
          onClose={handleAlertClose}
        >
          {apiFailureMessage}
        </Alert>
      )}
      {showApiSuccess && (
        <Alert
          severity="success"
          variant="outlined"
          sx={{ padding: "25px", margin: "20px" }}
          onClose={handleAlertClose}
        >
          {apiSuccessMessage}
        </Alert>
      )}
      {showApiInfo && (
        <Alert
          severity="info"
          variant="outlined"
          sx={{ padding: "25px", margin: "20px" }}
          onClose={handleAlertClose}
        >
          {apiInfoMessage}
        </Alert>
      )}
      {spinner && (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
          <h2 style={{ textAlign: "center" }}>Please wait...</h2>
        </div>
      )}
      {!spinner && (
        <div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {REGULATIONS_CONSTANTS.STEPPER_TITLES.map((label) => (
                <Step key={label}>
                  <StepLabel className="stepper-label">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div className="add-regulation-form">
              <Typography
                variant="h5"
                gutterBottom
                className="stepper-content-title"
              >
                {REGULATIONS_CONSTANTS.STEPPER_TITLES[activeStep]}
                <p className="stepper-content-subTitle">
                  {REGULATIONS_CONSTANTS.STEPPER_SUB_TITLES[activeStep]}
                </p>
              </Typography>
              <Divider />
              {showFormInvalid && (
                <Alert severity="error">
                  {activeStep === 2
                    ? REGULATIONS_CONSTANTS.UPLOAD_PARTS_ERROR
                    : REGULATIONS_CONSTANTS.FORM_VALIDITY_ERROR}
                </Alert>
              )}
              {activeStep === 0 && (
                <div>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.REGULATION_HEADER_LABELS[0]}
                    </FormLabel>
                    <TextField
                      id="new-reg-title"
                      type="text"
                      variant="outlined"
                      value={newRegulation.regTitle}
                      inputProps={{ maxLength: 40 }}
                      helperText="Title must be below 40 charecters"
                      onChange={(e) => handleFormUpdateByKey(e, "regTitle")}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                  >
                    <FormLabel className="stepper-form-label" sx={{ m: 1 }}>
                      {
                        REGULATIONS_CONSTANTS
                          .REGULATION_DETAILS_HEADER_LABELS[0]
                      }
                    </FormLabel>
                    <TextareaAutosize
                      id="new-reg-desc"
                      aria-label="empty textarea"
                      minRows={5}
                      placeholder="Describe about the regulation"
                      variant="outlined"
                      value={newRegulation.regDesc}
                      onChange={(e) => handleFormUpdateByKey(e, "regDesc")}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                  >
                    <FormLabel className="stepper-form-label" sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.REGULATION_RULE}
                    </FormLabel>
                    <TextField
                      id="new-reg-ruleNo"
                      type="text"
                      variant="outlined"
                      value={newRegulation.ruleNo}
                      onChange={(e) => handleFormUpdateByKey(e, "ruleNo")}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.RES_CODE}
                    </FormLabel>
                    <Select
                      id="new-reg-type"
                      value={newRegulation.regType}
                      onChange={(e) => handleFormUpdateByKey(e, "regType")}
                    >
                      <MenuItem value="Banned">
                        {REGULATIONS_CONSTANTS.BANNED}
                      </MenuItem>
                      <MenuItem value="Restricted">
                        {REGULATIONS_CONSTANTS.RESTRICTED}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {!isHideDate && (
                    <FormControl
                      sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                    >
                      <FormLabel required sx={{ m: 1 }}>
                        {
                          REGULATIONS_CONSTANTS
                            .REGULATION_DETAILS_HEADER_LABELS[1]
                        }
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label=""
                          value={effectiveDate}
                          onChange={handleDatePickerSelection}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                </div>
              )}
              {activeStep === 1 && (
                <div>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.JURISDICTION_LEVEL}
                    </FormLabel>
                    <Select
                      id="new-reg-level"
                      value={newRegulation.jurisdictionLevel}
                      onChange={(e) => handleFormUpdateByKey(e, "jurisdictionLevel")}
                    >
                      {REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS.map((each) => (
                        <MenuItem
                          key={`reg-level-${each.label}`}
                          value={each.value}
                        >
                          {each.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {GENERIC_CONSTANTS.COUNTRY}
                    </FormLabel>
                    <Select
                      id="new-reg-country"
                      value={newRegulation.countryCode}
                    >
                      <MenuItem value={newRegulation.countryCode}>
                        {newRegulation.countryCode}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                    disabled={
                      newRegulation.jurisdictionLevel
                      === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
                    }
                  >
                    <FormLabel
                      required={
                        !(
                          newRegulation.jurisdictionLevel
                          === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
                        )
                      }
                      sx={{ m: 1 }}
                    >
                      {GENERIC_CONSTANTS.STATE}
                    </FormLabel>
                    <Select
                      id="new-reg-state"
                      value={
                        newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
                          ? ""
                          : newRegulation.stateCode
                      }
                      onChange={(e) => handleFormUpdateByKey(e, "stateCode")}
                    >
                      {regStateCodes
                        && regStateCodes.length > 0
                        && regStateCodes.map((eachCode) => (
                          <MenuItem
                            key={`reg-state-code-${eachCode}`}
                            value={eachCode}
                          >
                            {eachCode}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                    disabled={
                      !(
                        newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[2].value
                      )
                      || newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
                    }
                  >
                    <FormLabel
                      required={
                        newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[2].value
                      }
                      sx={{ m: 1 }}
                    >
                      {GENERIC_CONSTANTS.COUNTY}
                    </FormLabel>
                    <Select
                      id="new-reg-county"
                      value={newRegulation.county}
                      onChange={(e) => handleFormUpdateByKey(e, "county")}
                    >
                      {regCounties
                        && regCounties.length > 0
                        && regCounties.map((eachCounty) => (
                          <MenuItem
                            key={`reg-county-${eachCounty}`}
                            value={eachCounty}
                          >
                            {eachCounty}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "100%", backgroundColor: "#fff" }}
                    disabled={
                      !(
                        newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[3].value
                      )
                      || newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[0].value
                    }
                  >
                    <FormLabel
                      required={
                        newRegulation.jurisdictionLevel
                        === REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS[3].value
                      }
                      sx={{ m: 1 }}
                    >
                      {GENERIC_CONSTANTS.CITY}
                    </FormLabel>
                    <Select
                      id="new-reg-city"
                      value={newRegulation.city}
                      onChange={(e) => handleFormUpdateByKey(e, "city")}
                    >
                      {regCities
                        && regCities.length > 0
                        && regCities.map((eachCity) => (
                          <MenuItem
                            key={`reg-city-${eachCity}`}
                            value={eachCity}
                          >
                            {eachCity}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {activeStep === 2 && (
                <div className="regulated-parts-section">
                  <DownloadTemplateButton />
                  <UploadPartListButton />
                  {newRegulation.parts && newRegulation.parts.length > 0 && (
                    <div className="uploaded-parts-table">
                      <DataGrid
                        rows={newRegulation.parts}
                        columns={partsTableColumns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                        classes={{ withBorderColor: "#001489" }}
                        getRowClassName={getRowClassName}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="stepper-actions">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {activeStep !== 0 && (
                    <Button
                      className="back-btn"
                      variant="outlined"
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => handleNavigation("Back")}
                    >
                      {GENERIC_CONSTANTS.BACK}
                    </Button>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    className={(inValidRows.length > 1 && activeStep === 2) ? "continue-btn-disabled" : "continue-btn"}
                    variant="contained"
                    onClick={() => handleNavigation("continue")}
                    disabled={inValidRows.length > 1 && activeStep === 2}
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    {activeStep === 2
                      ? GENERIC_CONSTANTS.SUBMIT
                      : GENERIC_CONSTANTS.SAVE_CONTINUE}
                  </Button>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};
export default AddRegulations;
